import { useCallback, useEffect, useState, type ChangeEvent } from 'react';
import { OutlinedTextFieldProps } from '@mui/material';
import toast from 'react-hot-toast';
import {
  API_E2E_POST_SEND_VERIFICATION,
  API_E2E_POST_VALIDATE_APPLICANT,
  API_E2E_POST_VERIFY_CHECK_CREATE_ACCOUNT,
} from 'src/api/e2e-request-objects';
import {
  E2eBaseCallDetailsI,
  e2eGetBaseAuthenticatedRequests,
  e2eGetBasePublicRequests,
} from 'src/api/query-logic/e2e-query-base-calls';
import { apiRequest } from 'src/api/request-handler';
import PhoneMask from 'src/components/masks/phone-mask';
import { AuthContextType } from 'src/contexts/auth/jwt-context';
import { E2eLogicObjectsWithParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import { E2eRoutingArgsI, handleE2eRoutingLogic } from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes } from 'src/enums/routes.enums';
import { fsTrackEvent } from 'src/utils/fullstory';

export const createAccountLogic_init = (routingPackage: E2eLogicObjectsWithParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id, store_name } = params;
  const { e2eStore, auth } = routingPackage;
  const { isAuthenticated } = auth;
  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid: params.store_uuid,
    e2e_form_id: params.e2e_form_id,
  };
  // this runs if user is not authenticated
  e2eGetBasePublicRequests(e2eStore, baseCallDetails, isAuthenticated);

  // this runs if they are
  e2eGetBaseAuthenticatedRequests(baseCallDetails, e2eStore, isAuthenticated);

  useEffect(() => {
    if (store_uuid && store_name && e2e_form_id) {
      e2eStore.initApplicationStore(store_uuid, store_name, e2e_form_id);
    }
  }, []);
};

export enum CreateAccountSectionsE {
  'form' = 'form',
  'comms' = 'comms',
  'otp' = 'otp',
}

export interface CreateAccountFormValuesI {
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
  password: string;
  confirm_password: string;
  fp_myfp_tc: {
    diclosure_id: string;
    accepted: boolean;
  };
  fp_customer_notice: {
    diclosure_id: string;
    accepted: boolean;
  };
  fp_privacy: {
    diclosure_id: string;
    accepted: boolean;
  };
  fp_bullets: {
    diclosure_id: string;
    accepted: boolean;
  };
}

export interface CreateAccountCommsValuesI {
  fp_sms_consent: {
    diclosure_id: string;
    accepted: boolean;
  };
  fp_additional_communications: {
    diclosure_id: string;
    accepted: boolean;
  };
}
export interface CreateAccountSectionValuesI {
  form: CreateAccountFormValuesI;
  comms: CreateAccountCommsValuesI;
}
export interface CreateAccountLogicI extends ComponentLogicI {
  fn: {
    validateUserForm: (values: CreateAccountFormValuesI) => Promise<boolean>;
    handleCommunicationNotice: (values: any) => void;
    submitForm: (code: string) => Promise<any>;
    getInputProps: (name: string, endAdornment: any) => Partial<OutlinedTextFieldProps['InputProps']>;
    handleIsDisabled: (values: CreateAccountFormValuesI) => boolean;
    sendOtp: () => void;
  };
  v: {
    section?: CreateAccountSectionsE;
    sectionValues: CreateAccountSectionValuesI;
  };
}
const sv = {
  form: {
    first_name: '',
    last_name: '',
    mobile_phone: '',
    email: '',
    password: '',
    confirm_password: '',
    fp_myfp_tc: {
      diclosure_id: '',
      accepted: false,
    },
    fp_customer_notice: {
      diclosure_id: '',
      accepted: false,
    },
    fp_privacy: {
      diclosure_id: '',
      accepted: false,
    },
    fp_bullets: {
      diclosure_id: '',
      accepted: true,
    }, // this is auto true since there is no check
  },
  comms: {
    fp_sms_consent: {
      diclosure_id: '',
      accepted: false,
    },
    fp_additional_communications: {
      diclosure_id: '',
      accepted: false,
    },
  },
};
export const createAccountLogic_funcAndVals = (routingPackage: E2eLogicObjectsWithParams): CreateAccountLogicI => {
  const e2eStore = routingPackage.e2eStore;
  const auth = routingPackage.auth;
  const app_configurations = e2eStore.app_configurations;
  const { store_uuid, e2e_form_id } = routingPackage.params;
  const createAaso = e2eStore.createAaso;
  const [section, setSection] = useState<CreateAccountSectionsE>(CreateAccountSectionsE.form);
  const [sectionValues, setSectionValues] = useState<CreateAccountSectionValuesI>(sv);
  const [sentOtp, setSentOtp] = useState<boolean>(false);
  const [submittingAaso, setSubmittingAaso] = useState(false);

  const createAasoAndRoute = useCallback(
    async (a: AuthContextType) => {
      if (a && a.isAuthenticated && a.user && !submittingAaso) {
        const user = a.user;
        if (store_uuid && e2e_form_id && user && app_configurations) {
          try {
            setSubmittingAaso(true);
            const generatedAASO = await createAaso(user, store_uuid, e2e_form_id);
            routingPackage.aasoContext = { aaso: generatedAASO };
            const routingArgs: E2eRoutingArgsI = {
              currentStage: ApplicationStage.STARTED,
              currentE2eRoute: E2ERoutes.Welcome,
            };
            handleE2eRoutingLogic(routingPackage, routingArgs, generatedAASO);
          } catch (error) {
            console.error('Error creating AASO:', error);
          } finally {
            setSubmittingAaso(false);
          }
        }
      }
    },
    [store_uuid, e2e_form_id, app_configurations, routingPackage, handleE2eRoutingLogic, submittingAaso],
  );

  useEffect(() => {
    createAasoAndRoute(auth);
  }, [auth, app_configurations, createAasoAndRoute]);

  useEffect(() => {
    if (section === CreateAccountSectionsE.otp) {
      sendOtp();
    }
  }, [section]);

  const isPassword = (name: string) => name === 'password' || name === 'confirm_password';

  function getInputProps(name: string, endAdornment: any): Partial<OutlinedTextFieldProps['InputProps']> {
    const inputProps: any = {};

    if (isPassword(name)) {
      inputProps.endAdornment = endAdornment();
    }

    if (name === 'mobile_phone') {
      inputProps.inputComponent = PhoneMask;
    }
    return inputProps;
  }

  const handleIsDisabled = (values: CreateAccountFormValuesI) => {
    return (
      !(values.fp_myfp_tc.accepted && values.fp_customer_notice.accepted && values.fp_privacy.accepted) ||
      values.password !== values.confirm_password
    );
  };

  const validateUserForm = async (values: CreateAccountFormValuesI): Promise<boolean> => {
    // we are going to check if user already exists
    // R:TODO E2E P0 - just verify the below items have been done
    /**
     * - check that email doesn't already exist
     * - remember to lowercase all data (other than password)
     * - we need to check that their password meets standards and matches
     * - verify all data points (we can't come back so we need to check it now)
     * - we should also save the users form data via redis_cache ttl 24hrs
     *    - check against that form... make sure the users information is the same
     *    - save all data in lowercase and strip whitespace.. mobile number should be stripped of all non-numeric characters
     *    - no national code on mobile number
     */
    values.first_name = values.first_name.trim().toLowerCase();
    values.last_name = values.last_name.trim().toLowerCase();
    const res = await apiRequest(API_E2E_POST_VALIDATE_APPLICANT, { body: { applicant: values } });
    if (res) {
      if (res.emailExists) {
        toast.error('Email already exists');
        return false;
      }
      if (res.isValidForm) {
        setSectionValues({ ...sectionValues, form: values });
        setSection(CreateAccountSectionsE.comms);
        return true;
      } else {
        toast.error('Invalid form');
        return false;
      }
    }
    return false;
  };

  const handleCommunicationNotice = (values: any) => {
    setSectionValues({ ...sectionValues, comms: values });
    setSection(CreateAccountSectionsE.otp);
  };

  const sendOtp = async (bypass?: boolean) => {
    console.log({ bypass });
    if (sentOtp && !bypass) return;
    const email = sectionValues.form.email;
    const mobile = sectionValues.form.mobile_phone;

    const cleansedMobile = mobile.replace(/\D/g, '');
    await apiRequest(API_E2E_POST_SEND_VERIFICATION, { body: { toEmail: email, toSms: cleansedMobile } });
    setSentOtp(true);
  };

  //should probably move this to a utils file
  const formatPhone = (phone: string) => (phone.startsWith('+1') ? phone : `+1${phone}`);

  const verifyAndCreateAccount = async (code: string): Promise<any> => {
    const mobile = sectionValues.form.mobile_phone;
    // R:TODO E2E P0 - verify that disclosures are being captured correctly
    const cleansedMobile = mobile.replace(/\D/g, '');
    return await apiRequest(API_E2E_POST_VERIFY_CHECK_CREATE_ACCOUNT, {
      body: {
        otp_code: { to: formatPhone(cleansedMobile), code },
        data: sectionValues,
      },
    });
  };

  const submitForm = async (code: string) => {
    // I just made this so much more difficult for myself
    // const res = await verifyOtp(code);
    const email = sectionValues.form.email;
    const password = sectionValues.form.password;
    const res = await verifyAndCreateAccount(code);
    if (res && res.status === 'approved') {
      //do whatever
      auth.signIn(email, password);
      fsTrackEvent('Applicant Account Created', {
        email,
      });
    }
    return res;
  };

  return {
    fn: {
      validateUserForm,
      handleCommunicationNotice,
      submitForm,
      getInputProps,
      handleIsDisabled,
      sendOtp,
    },
    v: {
      section,
      sectionValues,
    },
  };
};
