export const estimatedPurchaseAmountObj = {
  default_details: {
    order: 1,
    faker: {
      object: 'manual',
      value: '3000',
    },
    label: 'Estimated Purchase Amount',
    component: null,
    mask: 'currency',
    enum: null,
    is_verification: null,
    disclosure: null,
    msg: 'While the estimated purchase amount is requested, please note that some payment options do not take this into account for their decision.',
    name: 'estimated_purchase_amount',
    type: 'string',
    validations: [],
    base_options: null,
    least_common_options: [],
    all_options: [],
    required: true,
    specialDisclosure: null,
    params: null,
  },
  id: 'estimated_purchase_amount',
  section: 'basicFormFields',
  always_required: null,
  lender_details: {
    lender_api_app: {
      '1': true,
      '2': null,
      '3': true,
      '5': true,
      '6': true,
      '7': true,
      '9': null,
      '11': true,
      '16': null,
      '17': true,
      '20': true,
      '23': null,
      '24': null,
      '27': true,
      '30': null,
      '31': null,
      '33': null,
      '34': null,
      '38': null,
      '41': null,
      '42': null,
      '43': null,
      '44': true,
      '46': null,
      '51': null,
      '54': null,
      '55': null,
      '56': null,
      '57': null,
      '58': null,
      '59': null,
      '63': null,
      '64': null,
      '66': null,
      '67': null,
      '68': null,
      '69': null,
      '71': null,
      '72': true,
      '73': null,
      '74': null,
      '76': null,
      '78': null,
      '79': null,
      '80': true,
      '83': false,
      '92': null,
      '96': true,
      '97': null,
      '102': true,
      '104': true,
      '106': true,
      '107': true,
    },
    lender_conditions: {},
    lender_masks: {},
    lender_options: {},
    lender_required: {
      '1': true,
      '3': true,
      '5': true,
      '6': true,
      '7': true,
      '11': true,
      '16': true,
      '17': true,
      '20': true,
      '27': true,
      '56': true,
      '57': true,
      '58': true,
      '80': true,
      '83': true,
      '96': true,
      '97': true,
      '102': true,
      '104': true,
      '106': true,
      '107': true,
    },
    lender_web_app: {
      '1': true,
      '2': true,
      '3': false,
      '5': true,
      '6': true,
      '7': false,
      '9': true,
      '11': true,
      '16': true,
      '17': false,
      '20': true,
      '23': true,
      '24': true,
      '27': true,
      '30': true,
      '31': true,
      '33': true,
      '34': true,
      '38': true,
      '41': true,
      '42': true,
      '43': true,
      '44': true,
      '46': true,
      '51': true,
      '54': true,
      '55': true,
      '56': true,
      '57': true,
      '58': true,
      '59': true,
      '63': true,
      '64': true,
      '66': true,
      '67': true,
      '68': true,
      '69': true,
      '71': true,
      '72': true,
      '73': true,
      '74': true,
      '76': true,
      '78': true,
      '79': true,
      '80': true,
      '83': true,
      '92': true,
      '96': true,
      '97': true,
      '102': true,
      '104': true,
      '106': true,
      '107': false,
    },
    lender_format: {
      '1': 'string',
      '20': ['integer'],
      '43': ['integer', 'string'],
      '44': ['string'],
      '66': ['string', 'convertToDecimal'],
      '68': ['integer'],
      '69': ['integer', 'string'],
    },
    lenders_included: [
      '1',
      '2',
      '3',
      '5',
      '6',
      '7',
      '9',
      '11',
      '16',
      '17',
      '20',
      '23',
      '24',
      '27',
      '30',
      '31',
      '33',
      '34',
      '38',
      '41',
      '42',
      '43',
      '44',
      '46',
      '51',
      '54',
      '55',
      '56',
      '57',
      '58',
      '59',
      '63',
      '64',
      '66',
      '67',
      '68',
      '69',
      '71',
      '72',
      '73',
      '74',
      '76',
      '78',
      '79',
      '80',
      '83',
      '92',
      '96',
      '97',
      '102',
      '104',
      '106',
      '107',
    ],
  },
  final_details: {
    order: 1,
    faker: {
      object: 'manual',
      value: '3000',
    },
    label: 'Estimated Purchase Amount',
    component: null,
    mask: 'currency',
    enum: null,
    is_verification: null,
    disclosure: null,
    msg: 'While the estimated purchase amount is requested, please note that some payment options do not take this into account for their decision.',
    name: 'estimated_purchase_amount',
    type: 'string',
    validations: [],
    base_options: null,
    least_common_options: [],
    all_options: [],
    required: true,
    specialDisclosure: null,
    params: null,
  },
};
