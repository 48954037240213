import { useRef, useEffect, useState } from 'react';
import { FormRenderProps } from 'react-final-form';

export const useFormErrorScroll = (formProps: FormRenderProps) => {
  const fieldRefs = useRef<{ [key: string]: HTMLElement }>({});
  const [currentErrorIndex, setCurrentErrorIndex] = useState<number>(0);
  const [errorKeys, setErrorKeys] = useState<string[]>([]);

  useEffect(() => {
    if (formProps.errors) {
      setErrorKeys(Object.keys(formProps.errors));
    }
  }, [formProps.errors]);

  const scrollToError = (errorKey: string) => {
    const element = fieldRefs.current[errorKey];
    if (element) {
      const yOffset = -100;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      element.focus();
      
      // Use Final Form's batch functionality to touch the field
      formProps.form.batch(() => {
        formProps.form.focus(errorKey);
        formProps.form.blur(errorKey);
      });
    }
  };

  const registerFieldRef = (fieldId: string, element: HTMLElement | null) => {
    if (element) {
      fieldRefs.current[fieldId] = element;
    }
  };

  const handleSubmitWithErrors = (e: React.MouseEvent | React.FormEvent) => {
    e.preventDefault();
    if (errorKeys.length > 0) {
      // Touch all fields with errors
      formProps.form.batch(() => {
        errorKeys.forEach(key => {
          formProps.form.focus(key);
          formProps.form.blur(key);
        });
      });
      setCurrentErrorIndex(0);
      scrollToError(errorKeys[0]);
    }
  };

  const handleFieldBlur = (fieldId: string) => {
    if (errorKeys.length > 0) {
      const currentIdx = errorKeys.indexOf(fieldId);
      if (currentIdx !== -1) {
        const nextIndex = currentIdx + 1;
        if (nextIndex < errorKeys.length) {
          setCurrentErrorIndex(nextIndex);
          scrollToError(errorKeys[nextIndex]);
        } else if (nextIndex === errorKeys.length) {
          const submitButton = document.querySelector('[type="submit"]');
          if (submitButton) {
            submitButton.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }
    }
  };

  return {
    registerFieldRef,
    handleSubmitWithErrors,
    handleFieldBlur
  };
};