/* eslint-disable */
import React from 'react';
import type { FC } from 'react';
import { Grid, TextField } from '@mui/material';
import { Field, FormRenderProps } from 'react-final-form';
import DynamicInput from 'src/pages/application/components/dynamic-input';

interface DynamicFieldI {
  formProps: FormRenderProps;
  objSchema: any;
  id: string;
  required_fields?: string[];
  // modified line
  registerFieldRef?: (fieldId: string, element: HTMLElement | null) => void;
  handleFieldBlur?: (fieldId: string) => void;
  // end modified
}

const DynamicField: FC<DynamicFieldI> = (props: DynamicFieldI) => {
  // modified line - Added registerFieldRef and handleFieldBlur
  const { formProps, objSchema, id, required_fields, registerFieldRef, handleFieldBlur } = props;

  return (
    <Grid item md={3} xs={12} key={`dynamic_field_grid${id}`}>
      <Field name={id} key={`field_${id}`}>
        {(fieldProps) => (
          <div key={`dynamic_field_div${id}`}>
            <DynamicInput
              key={`dynamic_field_input_${id}`}
              formProps={formProps}
              objSchema={objSchema}
              fieldProps={fieldProps}
              keyValue={id}
              required_fields={required_fields}
              // modified line
              registerFieldRef={registerFieldRef}
              handleFieldBlur={handleFieldBlur}
              // end modified
            />
          </div>
        )} 
      </Field>
    </Grid>
  );
};

export default DynamicField;
