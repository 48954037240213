import { FC, ReactNode, useEffect, useState } from 'react';
import { Box, styled, ThemeProvider, useMediaQuery } from '@mui/material';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import theme from '../theme';
import MainHeader from './components/MainHeader';
import WelcomeHeader from './components/WelcomeHeader';

import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';

import { API_E2E_SUBMIT_MARKETING_LINKS } from 'src/api/e2e-request-objects';
import { apiRequest } from 'src/api/request-handler';
import { useAuth } from 'src/hooks/use-auth';
import { fsSetIdentify, fsStart } from 'src/utils/fullstory';

interface LayoutProps {
  children: ReactNode;
}

export enum LocalStorageE {
  'mLink' = 'mLink',
  'cfaConfigId' = 'cfaConfigId',
}

const LayoutContainer = styled(Box)<{ isWelcome: boolean; isMobile: boolean }>(({ isWelcome, isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  ...(isWelcome &&
    isMobile && {
      backgroundImage: `url('/assets/e2e-redesign/images/welcome-header.png')`,
    }),
}));

interface PotentialParamsToSave extends Record<string, string | undefined> {
  store_name?: string;
  store_uuid?: string;
  e2e_form_id?: string;
}
const MainLayout: FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const { user, isAuthenticated } = useAuth();
  const isWelcome = location.pathname.endsWith('/welcome');
  const [fsInit, setFsInit] = useState<boolean>(false);
  const params = useParams<PotentialParamsToSave>();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showUserName = () => {
    // TODO E2E P0 - make this look better.. need to get with vincent
    if (user) {
      return (
        <p>
          {user.first_name} {user.last_name}
        </p>
      );
    }
    return null;
  };

  const submitCfaMarketingLink = async (cfaId: string, mklId: string, qr?: string | null) => {
    try {
      const res = await apiRequest(API_E2E_SUBMIT_MARKETING_LINKS, {
        body: { cfa_config_id: cfaId, marketing_links_id: mklId, qr },
      });
      if (res && res.cfa_config_id) {
        // save mLink id to local storage
        window.localStorage.setItem(LocalStorageE.mLink, mklId);
        console.log('successfully saved cfa marketing link');
      }
    } catch (e) {
      console.log('failed to submit cfa mlink', e);
      window.localStorage.removeItem(LocalStorageE.mLink);
    }
  };
  useEffect(() => {
    if (params?.e2e_form_id) {
      const mLink = searchParams.get('mLink'); // Will return "20" if URL has ?mLink=20
      const qr = searchParams.get('qr');
      // mLink will be null if the parameter doesn't exist
      if (mLink && params?.e2e_form_id) {
        console.log('setting form link');
        submitCfaMarketingLink(params.e2e_form_id, mLink, qr);
      } else {
        try {
          // remove mLink from local storage (blank route)
          // window.localStorage.removeItem(LocalStorageE.mLink);
        } catch (e) {}
      }
    }
  }, []);
  useEffect(() => {
    const set = fsStart();
    if (set) {
      setFsInit(set);
    }
  }, []);

  useEffect(() => {
    if (fsInit && user && isAuthenticated && user.id && user.email) {
      // init full story once user has logged in
      fsSetIdentify(user);
    }
  }, [user, fsInit]);

  useEffect(() => {
    const mLink = window.localStorage.getItem(LocalStorageE.mLink);
    console.log({ mLink });
    // return () => {
    //   console.log('clear local storage');
    //   window.localStorage.clear();
    // };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LayoutContainer isWelcome={isWelcome} isMobile={isMobile}>
        {isWelcome && isMobile ? <WelcomeHeader /> : <MainHeader />}
        {/* {isAuthenticated && showUserName()} */}
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
      </LayoutContainer>
    </ThemeProvider>
  );
};

export default MainLayout;
