import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
// TODO - removed this due to error
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { silenceIrrelevantErrors } from 'src/utils/error';

import { App } from './app';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

if (tagManagerArgs.gtmId) {
  TagManager.initialize(tagManagerArgs);
} else {
  console.warn('GTM ID is not defined. Tag Manager will not be initialized.');
}

silenceIrrelevantErrors();
createRoot(document.getElementById('root') as HTMLElement).render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>,
);
