import { useEffect, useState } from 'react';
import { apiRequest } from 'src/api/request-handler';
import { API_GET_DASHBOARD_APPLICATIONS, API_GET_DASHBOARD_OPEN_OFFERS } from 'src/api/request-objects';
import {
  e2eConfigLogic_buildAppOffersObject,
  e2eConfigLogic_buildNotOffers,
  ProviderOffersI,
} from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { DashboardApplicationInfoI } from 'src/e2e-redesign/e2e-dashboard/views/dashboard.interface';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import {
  AutoApprovalOffersI,
  canShowAutoApprovedLenders,
  GroupedOfferI,
  NotOffersI,
  sortLendersByIds,
} from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import { AASO } from 'src/interfaces/aaso.interfaces';
import { Disclosure } from 'src/interfaces/disclosures.interfaces';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import { StoreI } from 'src/interfaces/store.interfaces';

export interface DashboardAppsI {
  offers: ProviderOffersI[];
  notOffers: NotOffersI[];
  combinedResponses: GroupedOfferI[];
  store: StoreI;
  autoApprovals: AutoApprovalOffersI[];
  created_at: string;
  // applicantInfo: DashboardApplicationInfoI[];
}
export interface DashboardLogicI extends ComponentLogicI {
  fn: {};
  v: {
    apps: DashboardAppsI[];
  };
}

export const dashboardLogic_funcAndV = (): DashboardLogicI => {
  // const [offers, setOffers] = useState<ProviderOffersI[]>([]);
  const [aasos, setAasos] = useState<AASO[]>([]);
  const [disclosures, setDisclosures] = useState<Disclosure[]>([]);
  const [lenders, setLenders] = useState<LendersI[]>([]);
  const [stores, setStores] = useState<{ id: number; store_name: string }[]>([]);
  const [forms, setForms] = useState<any[]>([]);
  const [apps, setApps] = useState<DashboardAppsI[]>([]);

  const handleGetApplications = async () => {
    const res: any = await apiRequest(API_GET_DASHBOARD_APPLICATIONS);
    if (!res) return;
    setAasos(res.aasos);
    setDisclosures(res.disclosures);
    setLenders(res.lenders);
    setStores(res.stores);
    setForms(res.forms);
  };

  useEffect(() => {
    handleGetApplications();
  }, []);

  useEffect(() => {
    // build applicaitons object
    const fullApps = [];
    if (aasos && aasos.length > 0 && forms.length > 0 && disclosures.length > 0 && lenders.length > 0) {
      for (const f of forms) {
        const formAaso = aasos.find((a) => a._id === f.aaso_id);
        const e2eForm = formAaso?.e2e_form_snapshot;
        const e2ePaths = e2eForm?.paths;
        const subs = f.api_pipe_submissions;
        const subApps: any = {
          autoApprovals: [],
          notOffers: [],
          offers: [],
          combinedResponses: [],
          store: stores.find((s) => s.id === f.store_id),
          created_at: formAaso?.created_at,
        };
        if (e2ePaths) {
          const lastPath = e2ePaths.find((p) => p.id === formAaso?.current_path_id);
          // set auto approvals
          const autoApprovalLenders = lenders.filter((l) => l?.api_pipe_settings?.e2e_config?.auto_approval?.enabled);
          if (autoApprovalLenders && lastPath && formAaso) {
            // auto approved providers exists - now do we show them?
            const autoArray: AutoApprovalOffersI[] = [];
            autoApprovalLenders.forEach((l) => {
              const canShow = canShowAutoApprovedLenders(lastPath, e2eForm, l, formAaso);
              if (canShow) {
                const lender_disclosures = disclosures.filter((d) => d.lender_id == l?.lender_id);
                autoArray.push({
                  lender: l,
                  lender_disclosures,
                  is_offer: false,
                  is_auto: true,
                });
              }
            });
            subApps.autoApprovals.push(...autoArray);
          }
          // set offers and not offers
          if (subs.length > 0 && lenders) {
            // TODO E2E P2 - optimize to run through lender offers
            const provOffers = e2eConfigLogic_buildAppOffersObject(subs, lenders, disclosures);
            if (provOffers?.length > 0) {
              subApps.offers.push(...provOffers);
            }
            const other: NotOffersI[] = e2eConfigLogic_buildNotOffers(subs, lenders, disclosures);
            if (other?.length > 0) {
              subApps.notOffers.push(...other);
            }
          }
          // set combinedResponses
          const allOffers: (ProviderOffersI | NotOffersI | AutoApprovalOffersI)[] = [
            ...subApps.offers,
            ...subApps.notOffers,
            ...subApps.autoApprovals,
          ];

          // Group by lender.lender_id
          const groupedOffers = allOffers.reduce((acc, currentOffer) => {
            const lenderId = currentOffer.lender.lender_id;
            if (!acc[lenderId]) {
              acc[lenderId] = {
                lender: currentOffer.lender,
                is_offer: currentOffer.is_offer,
                offers: [],
                notOffers: [],
                is_auto: currentOffer.is_auto,
                autoApprovals: [],
                lender_id: lenderId,
              };
            }

            if (currentOffer.is_offer) {
              acc[lenderId].offers.push(currentOffer as ProviderOffersI);
            } else if (currentOffer.is_auto) {
              acc[lenderId].autoApprovals.push(currentOffer as AutoApprovalOffersI);
            } else if (!currentOffer.is_auto && !currentOffer.is_offer) {
              acc[lenderId].notOffers.push(currentOffer as NotOffersI);
            }

            return acc;
          }, {} as Record<string, GroupedOfferI>);

          // Convert to array
          const groupedOffersArray = Object.values(groupedOffers);
          const lenderIds = allOffers.map((ao) => ao.lender.lender_id);
          if (lenderIds) {
            const sortedLenders = sortLendersByIds(groupedOffersArray, lenderIds);
            const final = sortedLenders.map((l) => {
              return { ...l };
            });
            // setCombinedResponses(groupedOffersArray.reverse());
            subApps.combinedResponses.push(...final);
          }
        }
        fullApps.push(subApps);
      }
    }
    setApps(fullApps);
  }, [aasos, forms, stores, disclosures, lenders]);

  return {
    fn: {},
    v: {
      // applicantInfo,
      apps,
    },
  };
};
