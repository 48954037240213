import React from 'react';
import { Seo } from 'src/components/seo';
import {
  E2eLogicObjectsWithParams,
  e2eLogicUtils_getBaseRoutingLogicObjects,
} from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import CommunicationForm from 'src/e2e-redesign/views/CommunicationNotice/CommunicationForm';
import {
  createAccountLogic_funcAndVals,
  createAccountLogic_init,
  CreateAccountSectionsE,
} from 'src/e2e-redesign/views/CreateAccountNewCustomer/logic/create-account-logic';
import OtpVerification from 'src/e2e-redesign/views/CreateAccountNewCustomer/otp-verification';
import { DisclosureTypeE } from 'src/interfaces/disclosures.interfaces';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';
import CreateAccountForm from './CreateAccountForm';

export default function CreateAccountNewCustomer() {
  const routingPackage: E2eLogicObjectsWithParams = e2eLogicUtils_getBaseRoutingLogicObjects();
  createAccountLogic_init(routingPackage);
  const disclosures = routingPackage.e2eStore.disclosures;
  const fp_sms_consent = disclosures.find((d) => d.disclosure_type === DisclosureTypeE.fp_sms_consent);
  const fnv = createAccountLogic_funcAndVals(routingPackage);
  const { validateUserForm, handleCommunicationNotice, submitForm, getInputProps, handleIsDisabled, sendOtp } = fnv.fn;
  const { section, sectionValues } = fnv.v;
  sectionValues.comms.fp_sms_consent.diclosure_id =
    fp_sms_consent?._id ?? sectionValues.comms.fp_sms_consent.diclosure_id;

  const handleSections = () => {
    switch (section) {
      case CreateAccountSectionsE.comms:
        return <CommunicationForm initialValues={sectionValues.comms} onSubmit={handleCommunicationNotice} />;
      case CreateAccountSectionsE.otp:
        return <OtpVerification onSubmit={submitForm} sendOtp={sendOtp} />;
      case CreateAccountSectionsE.form:
      default:
        return (
          <CreateAccountForm
            initialValues={sectionValues.form}
            onSubmit={validateUserForm}
            getInputProps={getInputProps}
            handleIsDisabled={handleIsDisabled}
          />
        );
    }
  };

  const getSectionDetails = () => {
    switch (section) {
      case CreateAccountSectionsE.comms:
        return {
          t: 'Communication Notice',
          p: fp_sms_consent ? fp_sms_consent.document : '',
        };
      case CreateAccountSectionsE.otp:
        return { t: 'Verification', p: 'Enter 6 digit verification numbers' };
      case CreateAccountSectionsE.form:
      default:
        return { t: 'Create Account', p: 'To get started, please create an account.' };
    }
  };

  const d = getSectionDetails();
  return (
    <>
      <Seo title={d.t} />
      <ThemedContainer>
        <PageIntro title={d.t} paragraph={d.p} />
        {handleSections()}
      </ThemedContainer>
    </>
  );
}
